.Home {

}
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.itemFoto {
    margin: 2%;
    flex:1;
    height: 330px;
    min-width: 220px;
    max-width: 300px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    object-fit: contain;
}
.foto {
    min-height: 100%;
    width: 100%;
}

.itemText {
    margin: 2%;
    flex: 1;
    /*background-color: rgba(0,0,0 , 0.2);*/
    background-color: white;
    opacity: 0.7;
    padding:8px;
    border-radius: 10px;
    min-width: 300px;
    font-size: smaller;
}

