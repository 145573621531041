.Praxis {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.itemFoto {
    margin: 3%;
    flex:1;
    background-color: white;
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    object-fit: contain;
    align-items: center;
    min-width: 250px;
}

.itemFoto img {
    width: 100%;
    aspect-ratio: initial ;
    align-items: center;
    object-fit: cover;
}

.itemText {
    margin: 3%;
    flex: 5;
    height: 200px;
    background-color: white;
    opacity: 0.8;
    padding: 8px;
    border-radius: 10px;
}
