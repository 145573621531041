.About {}

.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.itemFoto {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    max-height: 45vh;
    align-items: center;
    border-radius: 10px;
    margin: 2%;
}

.foto {
    width: 100%;
    display: block;
    height: auto;
    /*max-width:230px;
    max-height:95px;
    height: auto;*/
}

.fotoText {
    position: absolute;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 3.8vw;
    font-weight: bold;
    left: 30%;
    right: 30%;
}


.itemText {
    flex-direction: row;
    margin: 2%;
    flex: 1.5;
    background-color: white;
    opacity: 0.8;
    padding: 8px;
    border-radius: 10px;
    list-style-position: inside;
    list-style-type: circle; /*square*/
    font-size: smaller;
    min-width: 300px;
}

.itemText li {
    width: 100%;
    float: left;
}

.textParagr {
    opacity: 1 ;
}
