.Colorbar {
    align-items: center;
    justify-content: center;
    flex-direction:colum;
    flex-grow: 1;
}
.Gradient {
        flex: 1;
        height: 21;
        align-items: center;
        justify-content: center;
}
