.Navigationbar {
    background-color: white;
    opacity: 0.7;
    /*background-color: rgba(0,0,0,0.1);*/

    /*color: slategray;*/

}
.navbarTabs {
    align-items: end !important;

}

.navbarExpand {
    margin-top: auto;
    bottom:0;
}

.navLink {
    font-size: x-large;
    font-weight: bold;
    color: #7ecce3 !important;
    position: center;
}






/* https://upmostly.com/tutorials/react-background-image */
