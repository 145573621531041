.Contact {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.itemText {
    margin: 2%;
    flex: 1;
    background-color: white;
    padding:8px;
    border-radius: 10px;
    min-width: 200px;
    font-size: smaller;
    opacity: 0.75;
}
.itemMap {
    margin: 2%;
    flex: 1;
    background-color: white;
    padding:8px;
    border-radius: 10px;
    min-width: 200px;
    font-size: smaller;
    overflow: hidden;
    object-fit: contain;
}

.itemFoto {

    margin: 2%;
    flex:1;
    height: 200px;
    background-color: white;
    opacity: 1;
    border-radius: 10px;
    min-width: 150px;
    overflow: hidden;
    object-fit: contain;
    /*
    margin: 2%;
    flex: 1;
    background-color: white;
    padding:8px;
    border-radius: 10px;
    overflow: hidden;
    max-height: 45vh;
    align-items: center;*/
}



